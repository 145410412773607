// import PropTypes from 'prop-types';
import Link from '@scandipwa/scandipwa/src/component/Link';
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import { PureComponent } from 'react';

import NoPropagationEvent from 'Component/NoPropagateEvents';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductName/Component/ProductNameComponent */
export class ProductNameComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    render() {
        const { product: { name, url = '' } } = this.props;

        return (
          <NoPropagationEvent>

          <Link
            block="ProductCardDetails"
            elem="Link"
            to={ url }
          >
            <p
              block="ProductCardDetails"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length="medium" />
            </p>
          </Link>
          </NoPropagationEvent>

        );
    }
}

export default ProductNameComponent;
