import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { getProductPrice } from 'Util/Product';

import ProductPrice from 'Component/ProductPrice';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        reverse: PropTypes.bool
    };

    static defaultProps = {
        reverse: false
    };

    render() {
        const {
            reverse,
            product: { categories }
        } = this.props;

        return (
            <div block="ProductCardDetails" elem="PriceWrapper">
                <ProductPrice
                  price={ getProductPrice(this.props.product) }
                  reverse={ reverse }
                  categories={ categories }
                  promoPosition="bottom_primolivello"
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                />
            </div>
        );
    }
}

export default ProductPriceComponent;
