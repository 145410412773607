/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-bind */
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SingleColumn from 'Component/ProductCardDetails/Layout/SingleColumn.component';
import { ONE_COLUMN_LAYOUT, THREE_COLUMN_LAYOUT, TWO_COLUMN_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';

/** @namespace Pwa/Component/ProductCardDetails/Layout/MultiProductsSliderMobile/Component/MultiProductsSliderMobileComponent */
export class MultiProductsSliderMobileComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        colorChanger: PropTypes.func.isRequired,
        layout: PropTypes.oneOf([
            ONE_COLUMN_LAYOUT,
            TWO_COLUMN_LAYOUT,
            THREE_COLUMN_LAYOUT
        ])
    };

    static defaultProps = {
        layout: ONE_COLUMN_LAYOUT
    };

    state = {
        swiper: {}
    };

    componentDidUpdate(prevProps) {
        const { layout: oldLayout } = prevProps;
        const { layout: newLayout } = this.props;
        const { swiper } = this.state;

        if (oldLayout !== newLayout) {
            swiper.update();
        }
    }

    render() {
        const { product: { mc_multiprice, sku }, colorChanger } = this.props;

        mc_multiprice.sort((a, _b) => (a.sku === sku ? -1 : 1));

        return (
            <div block="ProductCardDetails" mods={ { multiple: true, slider: true } }>
                <div block="ProductCardDetails" elem="MultiProductsSliderMobile">
                    <div block="ProductCardDetails" elem="Prev" id={ `prev-${mc_multiprice[0].sku}` }>
                        <i className="fa fa-chevron-left" />
                    </div>
                    <Swiper
                      loop
                      navigation={ {
                          nextEl: `#next-${mc_multiprice[0].sku}`,
                          prevEl: `#prev-${mc_multiprice[0].sku}`
                      } }
                      onSwiper={ (swiper) => {
                          this.setState({ swiper });
                      } }
                    >
                        { mc_multiprice.map((item, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <SwiperSlide key={ idx }>
                                <SingleColumn
                                  product={ item }
                                  colorChanger={ colorChanger }
                                  isDisabled={ (idx !== 0) }
                                />
                            </SwiperSlide>
                        )) }
                    </Swiper>
                    <div block="ProductCardDetails" elem="Next" id={ `next-${mc_multiprice[0].sku}` }>
                        <i className="fa fa-chevron-right" />
                    </div>
                </div>
            </div>
        );
    }
}

export default MultiProductsSliderMobileComponent;
