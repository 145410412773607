import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import IfStore from 'Component/IfStore';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductCertification/Component/ProductCertificationComponent */
export class ProductCertificationComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        size: PropTypes.PropTypes.oneOf([
            'small',
            'medium'
        ])
    };

    static defaultProps = {
        size: 'small'
    };

    render() {
        const { product: { catas, cosmob }, size } = this.props;

        if (window.location.pathname === '/reti-e-materassi.html'
            || window.location.pathname === '/somieres-y-colchones.html') {
            if (cosmob) {
                return (
                    <>
                    <IfStore storeCode="default">
                        <div
                          block="ProductCardDetails"
                          elem="CosmobReti"
                          mods={ { isSmall: (size === 'small') } }
                        />
                    </IfStore>
                    <IfStore storeCode="spain">
                            <div
                              block="ProductCardDetails"
                              elem="DormibedReti"
                              mods={ { isSmall: (size === 'small') } }
                            />
                    </IfStore>
                    </>
                );
            }

            return <div />;
        }

        /**
         * cosmob derives from product attributes, isCosmob depends on cosmob AND display rules
         */
        const isCatas = Boolean(catas);
        const isCosmob = Boolean(cosmob);
        if (!(isCatas || isCosmob)) {
            return null;
        }

        const certification_name = isCatas ? 'Catas' : 'Cosmob';

        return (
            <div
              block="ProductCardDetails"
              elem={ certification_name }
              mods={ { isSmall: (size === 'small') } }
            />
        );
    }
}

export default ProductCertificationComponent;
