// import PropTypes from 'prop-types';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import { PureComponent } from 'react';

import FlashSaleIndicator from 'Component/FlashSalesIndicator';
import ProductActionButton from 'Component/ProductCardDetails/Elements/ProductActionButton.component';
import ProductComposizioneTipo from 'Component/ProductCardDetails/Elements/ProductComposizioneTipo.component';
import ProductDescription from 'Component/ProductCardDetails/Elements/ProductDescription.component';
import ProductDimensions from 'Component/ProductCardDetails/Elements/ProductDimensions.component';
import ProductFinish from 'Component/ProductCardDetails/Elements/ProductFinish.component';
import ProductIconsComponent from 'Component/ProductCardDetails/Elements/ProductIcons.component';
import ProductLink from 'Component/ProductCardDetails/Elements/ProductLink.component';
import ProductName from 'Component/ProductCardDetails/Elements/ProductName.component';
import ProductPrice from 'Component/ProductCardDetails/Elements/ProductPrice.component';
import { getProductPrice } from 'Util/Product';

/** @namespace Pwa/Component/ProductCardDetails/Layout/SingleWithoutColor/Component/SingleWithoutColorComponent */
export class SingleWithoutColorComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    render() {
        const {
            product,
            product: {
                sku,
                mc_flashsale,
                mc_color_information: {
                    description: color_description
                }
            }
        } = this.props;
        const { items_total = 0 } = mc_flashsale || { };
        const hasColorDescription = color_description.trim();
        const showCountdown = (items_total > 0);

        return (
            <ProductLink
              product={ product }
              mix={ { block: 'ProductCardDetails', mods: { single: true, inline: true, withoutColor: true } } }
            >
                <div block="ProductCardDetails" elem="ContentWrapper">
                    <div block="ProductCardDetails" elem="Content">
                        <div block="ProductCardDetails" elem="SingleColumn" mods={ { isLeft: true } }>

                            <div block="ProductCardDetails" elem="MainInfo">
                                <ProductName product={ product } />
                                <ProductDescription product={ product } />
                                <ProductDimensions product={ product } />
                            </div>
                            <div block="ProductCardDetails" elem="FeaturesList">
                                <ProductIconsComponent product={ product } />
                                { hasColorDescription
                                && window.location.pathname !== '/reti-e-materassi.html'
                                && window.location.pathname !== '/somieres-y-colchones.html'
                                && <ProductFinish product={ product } /> }
                            </div>
                        </div>
                        <div block="ProductCardDetails" elem="SingleColumn" mods={ { isRight: true } }>
                            <div block="ProductCardDetails" elem="ColorWrapper">
                                { showCountdown && (
                                    <div>
                                        <FlashSaleIndicator
                                          flashsale={ mc_flashsale }
                                          sku={ sku }
                                          isInfoIcon={ false }
                                        />
                                    </div>
                                ) }
                            </div>
                            <div block="ProductCardDetails" elem="PriceContainer">
                                <ProductPrice promoPosition="top_column" product={ product } price={ getProductPrice(product) } reverse />
                                <ProductComposizioneTipo product={ product } />
                            </div>
                        </div>
                    </div>
                    <div block="ProductCardDetails" elem="ActionWrapper">
                        <ProductActionButton product={ product } />
                    </div>
                </div>
            </ProductLink>
        );
    }
}

export default SingleWithoutColorComponent;
