// import PropTypes from 'prop-types';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import { PureComponent } from 'react';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductFinish/Component/ProductFinishComponent */
export class ProductFinishComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    render() {
        const { product: { mc_color_information } } = this.props;

        if(mc_color_information?.description){
            return (
                <div block="ProductCardDetails" elem="Finish">
                    <span>{ mc_color_information?.description }</span>
                </div>
            );
        }

        return null
    }
}

export default ProductFinishComponent;
