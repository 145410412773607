// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import DimensionFormatter from 'Component/DimensionFormatter/DimensionFormatter.component';
import McDimensioniPopup from 'Component/McDimensioniPopup/McDimensioniPopup.container';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductDimensions/Component/ProductDimensionsComponent */
export class ProductDimensionsComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    renderPopover() {
        const {
            product: { sku, mc_products_sizes }
        } = this.props;

        const id_popup = `DIMENSIONI_PRODOTTO_${ sku}`;

        return (<McDimensioniPopup mcProductSizes={ mc_products_sizes } id={ id_popup } />);
    }

    renderDefault() {
        const { product: { mc_dimension_height, mc_dimension_width, mc_dimension_depth } } = this.props;

        return (
            <div block="ProductCardDetails" elem="Dimensions">
                <DimensionFormatter
                  height={ mc_dimension_height }
                  width={ mc_dimension_width }
                  depth={ mc_dimension_depth }
                />
            </div>
        );
    }

    render() {
        const {
            product: { mc_products_sizes }
        } = this.props;

        if (mc_products_sizes) {
            return this.renderPopover();
        }

        return this.renderDefault();
    }
}

export default ProductDimensionsComponent;
