/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import { PureComponent } from 'react';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductIcons/Component/ProductIconsComponent */
export class ProductIconsComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    getMaterassiInfo() {
        const {
            product: {
                mc_info_materasso
            }
        } = this.props;

        const ent = [];

        if (mc_info_materasso === null) {
            return ent;
        }

        const basePath = '/media/catalog/materassi';

        if (mc_info_materasso.mc_matt_str_ortop) {
            ent.push({
                image: `${basePath}/ortopedico.jpg`,
                text: mc_info_materasso.mc_matt_str_ortop_label
            });
        }

        if (mc_info_materasso.mc_matt_m_bonnell) {
            ent.push({
                image: `${basePath}/bonnell.jpg`,
                text: mc_info_materasso.mc_matt_m_bonnell_label
            });
        } else if (mc_info_materasso.mc_matt_m_insacchettate) {
            ent.push({
                image: `${basePath}/insacchettate.jpg`,
                text: mc_info_materasso.mc_matt_m_insacchettate_label
            });
        } else if (mc_info_materasso.mc_matt_no_molle) {
            ent.push({
                image: `${basePath}/no_molle.jpg`,
                text: mc_info_materasso.mc_matt_no_molle_label
            });
        }

        if (mc_info_materasso.mc_matt_riv_cotone) {
            ent.push({
                image: `${basePath}/cotone.jpg`,
                text: mc_info_materasso.mc_matt_riv_cotone_label
            });
        }

        if (mc_info_materasso.mc_matt_riv_trasp) {
            ent.push({
                image: `${basePath}/traspirante.jpg`,
                text: mc_info_materasso.mc_matt_riv_trasp_label
            });
        }

        if (mc_info_materasso.mc_matt_anallergico) {
            ent.push({
                image: `${basePath}/anallergico.jpg`,
                text: mc_info_materasso.mc_matt_anallergico_label
            });
        }

        /* if (mc_info_materasso.mc_matt_ecologico) {
            ent.push({
                image: `${basePath}/ecologico.jpg`,
                text: mc_info_materasso.mc_matt_ecologico_label
            });
        }

        if (mc_info_materasso.mc_matt_imbottitura) {
            ent.push({
                image: `${basePath}/imbottitura.jpg`,
                text: mc_info_materasso.mc_matt_imbottitura_label
            });
        } */

        return ent;
    }

    render() {
        if (window.location.pathname !== '/reti-e-materassi.html'
        && window.location.pathname !== '/somieres-y-colchones.html') {
            return (null);
        }
        const ent = this.getMaterassiInfo();

        return ent && ent.length > 0 && (
            <div className="MaterassoIcon">
                    { ent.map((item) => (
                            <div className="tooltip">
                                <img src={ item.image } alt="img" />
                                <span className="tooltiptext">
                                    { item.text }
                                </span>

                            </div>
                    )) }
            </div>
        );
    }
}

export default ProductIconsComponent;
