import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Single from 'Component/ProductCardDetails/Layout/Single.component';

/** @namespace Pwa/Component/ProductCardDetails/Layout/MultiProductsRows/Component/MultiProductsRowsComponent */
export class MultiProductsRowsComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        colorChanger: PropTypes.func.isRequired
    };

    render() {
        const { product: { mc_multiprice, sku }, colorChanger } = this.props;

        mc_multiprice.sort((a, _b) => (a.sku === sku ? -1 : 1));

        return (
            <div block="ProductCardDetails" mods={ { multiple: true, rows: true } }>
                <div block="ProductCardDetails" elem="MultiProductsRows">
                    <div block="ProductCardDetails" elem="Prev" id={ `prev-${mc_multiprice[0].sku}` }>
                        <i className="fa fa-chevron-left" />
                    </div>
                    <Swiper
                      loop
                      navigation={ {
                          nextEl: `#next-${mc_multiprice[0].sku}`,
                          prevEl: `#prev-${mc_multiprice[0].sku}`
                      } }
                    >
                    { mc_multiprice.map((item, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <SwiperSlide key={ idx }>
                            <Single product={ item } colorChanger={ colorChanger } isDisabled={ (idx !== 0) } />
                        </SwiperSlide>
                    )) }

                    </Swiper>
                    <div block="ProductCardDetails" elem="Next" id={ `next-${mc_multiprice[0].sku}` }>
                        <i className="fa fa-chevron-right" />
                    </div>
                </div>
            </div>
        );
    }
}

export default MultiProductsRowsComponent;
