/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import Link from '@scandipwa/scandipwa/src/component/Link';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddToCart from 'Component/AddToCart';
import NoPropagationEvent from 'Component/NoPropagateEvents';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductActionButton/Component/ProductActionButtonComponent */
export class ProductActionButtonComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        isDisplayMore: PropTypes.bool,
        mix: PropTypes.object
    };

    static defaultProps = {
        isDisplayMore: false,
        mix: {}
    };

    renderAddToCart() {
        const quantity = 1;

        const {
            product,
            mix
        } = this.props;

        if (product.mc_black_friday_counter !== undefined && product.mc_black_friday_counter !== null) {
            if (product.mc_black_friday_counter.items_remaining <= 0 && product.mc_black_friday_counter.items_remaining !== null) {
                return (
                    <button disabled className="Button AddToCart-Disabled ProductActions-AddToCart">
                        <span>{ __('Add to cart') }</span>
                    </button>
                );
            }
        }

        return (
            <NoPropagationEvent mix={ { mix } }>
                <AddToCart
                  configurableVariantIndex={ -1 }
                  product={ product }
                  mix={ { block: 'ProductActions', elem: 'AddToCart' } }
                  quantity={ quantity }
                  groupedProductQuantity={ { } }
                  productOptionsData={ { } }
                />
            </NoPropagationEvent>
        );
    }

    render() {
        const {
            product: { mc_vendibile_sito, url }, isDisplayMore, mix
        } = this.props;

        if (mc_vendibile_sito === 1 && !isDisplayMore) {
            return this.renderAddToCart();
        }

        const mods = !isDisplayMore ? { isLarge: true } : { isMedium: true };

        return (
            <NoPropagationEvent mix={ { mix } }>
                <Link
                  className="Button"
                  block="ProductCard"
                  elem="CTA"
                  mods={ mods }
                  to={ url }
                >
                    <span>
                        { __('Scopri di più') }
                    </span>
                </Link>
            </NoPropagationEvent>
        );
    }
}

export default ProductActionButtonComponent;
