/* eslint-disable fp/no-let */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import Image from 'Component/Image';
import NoPropagationEvent from 'Component/NoPropagateEvents';
import SecondaryLink from 'Component/SecondaryLink/SecondaryLink.container';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductColors/Component/ProductColorsComponent */
export class ProductColorsComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        location: PropTypes.any.isRequired,
        product: ProductType.isRequired,
        colorChanger: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
        layout: PropTypes.string.isRequired,
        size: PropTypes.PropTypes.oneOf([
            'small',
            'medium'
        ]),
        isVariant: PropTypes.bool
    };

    static defaultProps = {
        isDisabled: false,
        size: 'medium',
        isVariant: false
    };

    isIncludesCategory = () => {
        const { location: { pathname } } = this.props;
        const routes = [
            'cucine',
            'camere',
            'soggiorni',
            'tavoli',
            'bagni',
            'letti',
            'divani',
            'cocinas',
            'dormitorios',
            'salones',
            'mesas',
            'banos',
            'camas',
            'sofas'
        ];

        // Verifico se nel pathname è inclusa una delle parole presenti in routes
        return routes.some((element) => {
            if (pathname.includes(element)) {
                return true;
            }

            return false;
        });
    };

    iterateOverInformationColor = (mcColoriInformation, colorCode) => {
        // Prendo le entità che hanno il codice_colore uguale al colorCode (mc_color) passato...
        const filteredArray = mcColoriInformation.filter((a) => a.codice_colore === colorCode);
        // ...e, se esiste, ne ritorno la proprietà "image"
        return filteredArray[0] ? filteredArray[0].image : '';
    };

    renderClickText() {
        const {
            product,
            product: {
                mc_clicca_colori_presente,
                mc_clicca_colori_testo
            }
        } = this.props;

        const txtCliccaColoriTesto = (this.isIncludesCategory()) ? __('clicca_colori_testo_new') : mc_clicca_colori_testo;

        if (!mc_clicca_colori_presente) {
            return null;
        }

        return (
            <div block="ProductCardDetails" elem="ColorsTextLink">
                <SecondaryLink product={ product }>
                    <span>{ txtCliccaColoriTesto }</span>
                    <i className="fa fa-chevron-right" />
                </SecondaryLink>
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    changeColorHandler(sku) {
        const { colorChanger, isDisabled } = this.props;
        if (!isDisabled) {
            colorChanger(sku);
        }
    }

    renderColorBox(item, index) {
        const {
            product: {
                mc_color: productColor,
                mc_tessuti_colori_information,
                mc_finiture_colori_information
            },
            size,
            isVariant,
            location: { pathname }
        } = this.props;
        const { sku, mc_color, mc_color_information: { image_url, description = '' } } = item;
        const { isDisabled } = this.props;
        const isActive = (productColor && mc_color && (mc_color === productColor));

        const imageElem = (isVariant) ? 'VariantImage' : 'ColorImage';
        const itemElem = (isVariant) ? 'VariantItem' : 'ColorItem';
        const triggerElem = (isVariant) ? 'VariantTrigger' : 'ColorTrigger';

        const isIncludesCategory = this.isIncludesCategory();

        let urlImageColore = '';

        // Rotte in cui caricare le finiture e colori
        if ((pathname.includes('cucine')
            || pathname.includes('camere')
            || pathname.includes('soggiorni')
            || pathname.includes('tavoli')
            || pathname.includes('bagni')
            || pathname.includes('cocinas')
            || pathname.includes('dormitorios')
            || pathname.includes('salones')
            || pathname.includes('mesas')
            || pathname.includes('banos'))
            && mc_finiture_colori_information) {
            urlImageColore = this.iterateOverInformationColor(mc_finiture_colori_information, mc_color);
        }

        // Rotte in cui caricare i tessuti e colori
        if ((pathname.includes('letti')
            || pathname.includes('divani')
            || pathname.includes('camas')
            || pathname.includes('sofas'))
            && mc_tessuti_colori_information) {
            urlImageColore = this.iterateOverInformationColor(mc_tessuti_colori_information, mc_color);
        }

        return (
            <li
              block="ProductCardDetails"
              elem={ itemElem }
              key={ index }
              mods={ { isDisabled, isSmall: (size === 'small') } }
              className="tooltip-colors"
            >
                <div
                  onClick={ () => this.changeColorHandler(sku) }
                  onKeyDown={ () => this.changeColorHandler(sku) }
                  role="button"
                  tabIndex="0"
                  block="ProductCardDetails"
                  elem={ triggerElem }
                  mods={ { isActive } }
                >
                    <Image
                      src={ image_url }
                      ratio="custom"
                      alt={ description }
                      mix={ {
                          block: 'ProductCardDetails',
                          elem: imageElem,
                          mods: { isSmall: (size === 'small') }
                      } }
                    />
                    { isIncludesCategory && !pathname.includes('camerette') && !pathname.includes('dormitorios-juveniles') && window.matchMedia('(min-width: 769px)').matches
                        && (
                            <div
                              block="ProductCardDetails"
                              elem="ZoomedImg"
                              className="tooltipcolorstext"
                            >
                                <img src={ urlImageColore !== '' ? urlImageColore : image_url } alt={ description } />
                            </div>
                        ) }

                </div>
            </li>
        );
    }

    render() {
        const {
            location: { pathname }, product: { mc_available_colors, mc_etichetta_colore }, isVariant, layout
        } = this.props;

        const isBfWarmup = ['/offerte/bf2021.html', '/ofertas/bf2021.html'].includes(pathname);

        if (!mc_etichetta_colore || isBfWarmup) {
            return null;
        }

        return (
            <NoPropagationEvent mix={ { block: 'ProductCardDetails', elem: 'ColorElement' } }>
                <ul block="ProductCardDetails" elem="ColorList" mods={ { is_New: this.isIncludesCategory() && layout === 'two_column' } }>
                    { mc_available_colors && mc_available_colors.map(
                        (item, index) => this.renderColorBox(item, index)
                    ) }
                    { !isVariant && layout === 'two_column' && this.isIncludesCategory() && (
                        (<li className="clickText">{ this.renderClickText() }</li>
                        )) }
                </ul>
                { !isVariant && layout !== 'two_column' && this.renderClickText() }
            </NoPropagationEvent>
        );
    }
}

export default withRouter(ProductColorsComponent);
